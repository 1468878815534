import Flickity from "flickity";
import gsap from "gsap";
import LazyLoad from "vanilla-lazyload";
const HTML = document.querySelector("html");
class App {
  constructor() {
    this.lazyload = new LazyLoad({
      unobserve_entered: true,
      elements_selector: ".js-lazy",
      threshold: window.innerHeight * 0.8,
      callback_enter: ($el) => {
        if ($el.classList.contains("js-lazy-error-placeholder")) {
          $el.lazyLoadPlaceholder = $el.src;
        } else if ($el.dataset.src == void 0) {
          const figure = $el.closest("figure");
          if (figure)
            figure.classList.add("img-is-loaded");
        }
      },
      callback_loaded: (el) => {
        const figure = el.closest("figure");
        if (figure)
          figure.classList.add("img-is-loaded");
        window.dispatchEvent(new Event("resize"));
      },
      callback_error: ($el) => {
        if ($el.lazyLoadPlaceholder) {
          $el.src = $el.lazyLoadPlaceholder;
        }
      }
    });
    const toggleMenus = document.querySelectorAll(".toggle-menu");
    if (toggleMenus) {
      toggleMenus.forEach((toggle) => {
        toggle.addEventListener("click", (e) => {
          e.preventDefault();
          HTML.classList.toggle("open-menu");
        });
      });
    }
    this.tabs();
    this.carousel();
    this.collapse();
    this.accordion();
  }
  carousel() {
    const elts = document.querySelectorAll(".carousel");
    if (elts) {
      elts.forEach((carousel) => {
        new Flickity(carousel, {
          pageDots: false,
          cellAlign: "left",
          contain: true,
          on: {
            select(index) {
              const nbVisible = Math.ceil(this.size.width / this.slides[0].outerWidth);
              if (index == 0) {
                this.prevButton.element.classList.add("disabled");
              } else {
                this.prevButton.element.classList.remove("disabled");
              }
              if (index + nbVisible == this.cells.length + 1) {
                this.nextButton.element.classList.add("disabled");
              } else {
                this.nextButton.element.classList.remove("disabled");
              }
            }
          }
        });
      });
    }
  }
  collapse() {
    const elts = document.querySelectorAll("[data-collapse]");
    if (elts) {
      elts.forEach((el) => {
        el.addEventListener("click", (e) => {
          e.preventDefault();
          const target = document.querySelector(el.dataset.collapse);
          if (target) {
            target.classList.toggle("hide-container");
            gsap.to(target, {
              height: target.classList.contains("hide-container") ? 0 : target.children[0].offsetHeight,
              duration: 0.4
            });
          }
        });
      });
    }
  }
  tabs() {
    const elts = document.querySelectorAll(".tabs");
    if (elts) {
      elts.forEach((el) => {
        const triggers = el.querySelectorAll(".tabs-header a");
        const items = el.querySelectorAll(".tabs-item");
        const container = el.querySelector(".tabs-container");
        let current = -1;
        if (triggers) {
          let goto = function(index) {
            if (current == index)
              return;
            if (current >= 0) {
              gsap.to(items[current], {
                duration: 0.4,
                autoAlpha: 0
              });
              triggers[current].classList.remove("active");
            }
            if (current == index)
              return;
            if (items[index]) {
              triggers[index].classList.add("active");
              gsap.to(items[index], {
                duration: 0.4,
                autoAlpha: 1
              });
              gsap.to(container, {
                height: items[index].offsetHeight,
                duration: 0.4
              });
              current = index;
            }
          };
          let firstActive = -1;
          triggers.forEach((trigger, i) => {
            const d = trigger.innerText.split("/");
            const tabDate = new Date();
            tabDate.setFullYear("20" + d[2], parseInt(d[1]) - 1, parseInt(d[0]));
            tabDate.setSeconds(59);
            tabDate.setMinutes(59);
            tabDate.setHours(23);
            const now = new Date();
            if (tabDate >= now) {
              if (firstActive < 0)
                firstActive = i;
              trigger.addEventListener("click", (e) => {
                e.preventDefault();
                goto(i);
              });
            } else {
              trigger.closest("li").classList.add("disable");
            }
          });
          window.addEventListener("resize", () => {
            gsap.to(container, {
              height: items[current].offsetHeight,
              duration: 0.2
            });
          });
          setInterval(() => {
            window.dispatchEvent(new Event("resize"));
          }, 500);
          goto(firstActive);
        }
      });
    }
  }
  accordion() {
    const elts = document.querySelectorAll(".accordion");
    if (elts) {
      elts.forEach((el) => {
        const headers = el.querySelectorAll(".accordion-header");
        const items = el.querySelectorAll(".accordion-content");
        if (headers && items) {
          headers.forEach((header, i) => {
            header.addEventListener("click", (e) => {
              e.preventDefault();
              const parent = header.closest(".accordion-item");
              parent.classList.toggle("open");
              if (items[i]) {
                const child = items[i].children[0];
                const h = parent.classList.contains("open") ? child.offsetHeight : 0;
                gsap.to(items[i], {
                  duration: 0.4,
                  ease: "circ.inOut",
                  height: h,
                  autoAlpha: parent.classList.contains("open") ? 1 : 0
                });
              }
            });
          });
        }
      });
    }
  }
}
window.addEventListener("DOMContentLoaded", () => {
  new App();
});


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
